<template>
  <Layout>
    <b-button v-b-modal.add-location>Add location</b-button>

    <div class="locations-container">
      <div v-if="loading" class="text-center">Loading...</div>
      <div v-else>
        <div v-if="places.length">
          <div class="row mt-4">
            <div
              class="col-sm-4 col-md-3"
              v-for="place in places"
              :key="place._id"
            >
              <b-card>
                <strong> {{ place.title }}</strong> <br />
                Latitude : {{ place.location.coordinates[0] }}<br />
                Longintude : {{ place.location.coordinates[1] }}<br />
                <div class="d-flex justify-content-between">
                  <button class="location-edit-btn" @click="editPlace(place)">
                    Edit
                  </button>
                  <button
                    class="location-delete-btn"
                    @click="deletePlace(place)"
                  >
                    Delete
                  </button>
                </div>
              </b-card>
            </div>
          </div>
        </div>
        <div v-else>No Location available</div>
      </div>
    </div>

    <b-modal
      id="add-location"
      :title="values.title ? 'Update Location' : 'Add Location'"
      :hide-footer="true"
      @close="update = false"
    >
      <FormulateForm
        name="locationForm"
        @submit="submitLocation"
        v-model="values"
      >
        <FormulateInput
          type="text"
          name="title"
          label="Title"
          placeholder="Title here"
          validation="required"
        />

        <mapView
          v-on:locationChange="locationChange"
          :marker="marker"
          :center="center"
        />

        Latitude : {{ coordinate.lat }}, Longitude :{{ coordinate.lng }}
        <FormulateInput type="submit" class="mt-3 float-right">
          <span v-if="sending">
            <span v-if="!update"> Creating..</span
            ><span v-else>Updating..</span></span
          ><span v-else>
            <span v-if="!update"> Create</span><span v-else>Update</span>
          </span>
        </FormulateInput>
      </FormulateForm>
    </b-modal>
  </Layout>
</template>

<script>
import { locations } from "@/config/api/locations";
import Layout from "../../layouts/main";
import mapView from "@/components/maps/mapView";
export default {
  data() {
    return {
      sending: false,
      loading: false,
      coordinate: {},
      places: [],
      selectedLocation: {},
      marker: { position: { lat: 24.4860964, lng: 54.357658 } },
      center: { lat: 24.4860964, lng: 54.357658 },
      values: { title: "" },
      update: false,
    };
  },
  components: {
    Layout,
    mapView,
  },
  created() {
    this.getLocations();
  },
  methods: {
    addLocation(data) {
      const api = locations.create;
      api.data = data;
      console.log(api);
      this.sending = true;
      this.generateAPI(api)
        .then(() => {
          this.getLocations();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.sending = false;
          this.$bvModal.hide("add-location");
        });
    },
    updateLocation(data) {
      const api = locations.update;
      api.id = this.selectedLocation._id;
      api.data = data;
      this.sending = true;
      this.generateAPI(api)
        .then(() => {
          this.getLocations();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.sending = false;
          this.$bvModal.hide("add-location");
        });
    },
    getLocations() {
      const api = locations.get;

      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.places = res.data.places;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    locationChange(val) {
      this.coordinate = val;
    },
    submitLocation(data) {
      const dataFormatted = {
        title: data.title,
        cordinates: [this.coordinate.lat, this.coordinate.lng],
      };
      this.update
        ? this.updateLocation(dataFormatted)
        : this.addLocation(dataFormatted);
    },
    editPlace(place) {
      this.update = true;
      this.selectedLocation = place;
      const coordinates = place.location.coordinates;
      this.marker = { position: { lat: coordinates[0], lng: coordinates[1] } };
      this.center = { lat: coordinates[0], lng: coordinates[1] };
      this.values.title = place.title;
      this.$bvModal.show("add-location");
    },
    deletePlace(place) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete location.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = locations.delete;
            api.id = place._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Location deleted successfully!", {
                  title: "Location",
                  variant: "success",
                  solid: true,
                });
                this.getLocations();
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Location is not deleted,${err.response.data}`,
                  {
                    title: "Location",
                    variant: "danger",
                    solid: true,
                  }
                );
              });
          }
        });
    },
  },
};
</script>

<style>
.location-delete-btn {
  border: none;
  outline: none;
  background-color: brown;
  border-radius: 4px;
  color: white;
  margin-top: 8px;
  font-size: 12px;
}
.location-edit-btn {
  border: none;
  outline: none;
  background-color: rgb(75, 83, 158);
  border-radius: 4px;
  color: white;
  margin-top: 8px;
  font-size: 12px;
  min-width: 60px;
}
</style>
